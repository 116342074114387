import {
    ClientPureResponse,
    CreateJobOfferSeatInput,
    JobEmployeeDataResponse,
    JobEmployeeStatus,
    JobEmployeeTimesheetItemResponse,
    JobOfferSeatResponse, MaterialResponse,
    Maybe, ProductResponse, VisitDetailResponse,
    VisitListPureResponse,
    VisitRepeatDayListPureResponse,
    VisitRepeatDayResponse,
    VisitRepeating,
    VisitRepeatModifier,
    VisitResponse,
    VisitStatus,
    VisitStatusModifier
} from "../generated/graphql/graphql";
import {EventInput} from "@fullcalendar/core";
import {DateTime} from "luxon";
import {
    DateTimePlusVisitRepeating,
    GetDayOfWeekPositionInMonth,
    IDayOfWeekPositionInMonth,
    isOverlapping
} from "../utils/DateUtils";
import IVisitEvent from "../model/VisitEvent";
import {tt} from "../core/Localization";
import {kAppColors, kVisitStatusColors, kVisitStatusModifiersColors} from "../styles/AppThemeProcessor";
import {Theme} from "@mui/material";
import Icons8SandWatch from "../icons/Icons8SandWatch";
import Icons8InTransit from "../icons/Icons8InTransit";
import HammerIcon from "../icons/HammerIcon";
import Icons8Ok from "../icons/Icons8Ok";
import Icons8Cancel2 from "../icons/Icons8Cancel2";
import Icons8MediumRisk from "../icons/Icons8MediunRisk";
import Icons8CheckInCircle from "../icons/Icons8CheckInCircle";
import Icons8Invoice from "../icons/Icons8Invoice";
import Icons8InvoicePaid from "../icons/Icons8InvoicePaid";
import Icons8InvoiceNotPaid from "../icons/Icons8InvoiceNotPaid";
import Icons8LockOutlined from "../icons/Icons8LockOutlined";
import Icons8CalendarWithQuestionMark from "../icons/Icons8CalendarWithQuestionMark";
import Icons8CalendarWithCheckMark from "../icons/Icons8CalendarWithCheckmark";
import Icons8Reseller from "../icons/Icons8Reseller";
import {hasPermission} from "../ui/components/permissions/PermissionValid";
import {kActionView, kPermissionsMaterials, kPermissionsProducts, kPermissionsTimesheets} from "../core/constants";
import {filterProductsForVisit} from "./ProductService";
import {filterMaterialsForVisit} from "./MaterialService";
import {convertProductMaterials} from "./ProductMaterialService";
import {AddVatToPrice, calculateTimesheets, CalculateTotalPrice, paymentTypeToItemPaymentType} from "./CompanyService";

export const kVisitsListDisplayLimitPage = 30;

export interface IVisitsProcessParams {
    nonRepeating: VisitListPureResponse[];
    repeating: VisitListPureResponse[];
    visitRepeatDays: VisitRepeatDayListPureResponse[];
    from: DateTime;
    to: DateTime;
    sortDesc?: boolean;
    multipleEventsForMultiDay?: boolean; //THIS has to be TRUE always, FALSE version has issues with events!!!
}

/**
 * Process Visits to create dynamic children from repeating Visits.
 */
export function VisitsProcess(params: IVisitsProcessParams): IVisitEvent[] {
    const {nonRepeating, repeating, visitRepeatDays, from, to, sortDesc, multipleEventsForMultiDay} = params;

    let combined: IVisitEvent[] = [];

    for (const visitOf of nonRepeating) {
        const visitStartDate = DateTime.fromMillis(visitOf.startDate);
        const visitEndDate = DateTime.fromMillis(visitOf.endDate);

        const skipWeekends = visitOf.repeatModifier === VisitRepeatModifier.SkipWeekends;
        const skipDays = visitOf.skipDays.map(skipDay => DateTime.fromMillis(skipDay));

        if ((skipWeekends || skipDays.length > 0) && !visitStartDate.hasSame(visitEndDate, 'day')) {
            const visitStartTime = DateTime.fromMillis(visitOf.startTime);
            const visitEndTime = DateTime.fromMillis(visitOf.endTime);

            let chunks: { start: DateTime, end: DateTime }[] = [];

            if (skipWeekends) {
                let nextDate = visitStartDate;
                let startChunk = visitStartDate;
                let lastNonWeekendDate = visitStartDate;

                while (nextDate <= visitEndDate) {
                    if (nextDate.weekday === 7) {
                        // do nothing
                    } else if (nextDate.weekday === 6 && !(startChunk.weekday === 6 || startChunk.weekday === 7)) {
                        const endChunk = nextDate.minus({days: 1}).set({
                            hour: visitEndTime.hour,
                            minute: visitEndTime.minute,
                            second: visitEndTime.second
                        });

                        chunks.push({start: startChunk, end: endChunk});
                    } else if (nextDate.weekday === 1) {
                        startChunk = nextDate;
                    }

                    if (nextDate.weekday !== 6 && nextDate.weekday !== 7) {
                        lastNonWeekendDate = nextDate;
                    }

                    nextDate = nextDate.plus({days: 1});
                }

                if (startChunk < visitEndDate && lastNonWeekendDate.weekday !== 6 && lastNonWeekendDate.weekday !== 7) {
                    const startDateAlreadyInChunks = chunks.find(c => c.start.hasSame(startChunk, 'day'));

                    if (!startDateAlreadyInChunks) {
                        const endChunk = lastNonWeekendDate.set({
                            hour: visitEndTime.hour,
                            minute: visitEndTime.minute,
                            second: visitEndTime.second
                        });

                        chunks.push({start: startChunk, end: endChunk});
                    }
                }
            } else {
                chunks.push({start: visitStartDate, end: visitEndDate});
            }

            if (skipDays.length > 0) {
                const newChunks: { start: DateTime, end: DateTime }[] = [];

                for (const chunkOf of chunks) {
                    const startOfDayOfStart = chunkOf.start.startOf('day');
                    const endOfDayOfEnd = chunkOf.end.endOf('day');
                    const skipDaysForThisChunk = skipDays
                        .filter(skipDay => skipDay >= startOfDayOfStart && skipDay <= endOfDayOfEnd);
                    let daysForThisChunk = [];

                    let nextDate = chunkOf.start;
                    while (nextDate <= chunkOf.end) {
                        if (!skipDaysForThisChunk.find(skipDay => skipDay.hasSame(nextDate, 'day'))) {
                            daysForThisChunk.push(nextDate);
                        } else if (daysForThisChunk.length > 0) {
                            newChunks.push({
                                start: daysForThisChunk[0],
                                end: daysForThisChunk[daysForThisChunk.length - 1]
                            });

                            daysForThisChunk = [];
                        }
                        nextDate = nextDate.plus({days: 1});
                    }

                    if (daysForThisChunk.length > 0) {
                        newChunks.push({
                            start: daysForThisChunk[0],
                            end: daysForThisChunk[daysForThisChunk.length - 1]
                        });

                        daysForThisChunk = [];
                    }
                }

                chunks = newChunks;
            }

            let index = 0;

            for (const chunkOf of chunks) {
                const start = chunkOf.start.set({
                    hour: visitStartTime.hour,
                    minute: visitStartTime.minute,
                    second: visitStartTime.second
                });
                const end = chunkOf.end.set({
                    hour: visitEndTime.hour,
                    minute: visitEndTime.minute,
                    second: visitEndTime.second
                });

                const dynamic: IVisitEvent = {
                    ...visitOf,
                    isDynamicChild: true,
                    dynamicId: `${visitOf.id}_${index}`,
                    startDateOfDynamic: start,
                    endDateOfDynamic: end,
                };

                combined.push(dynamic);

                index++;
            }
        }
            // These are temporary while creating new Job/Visits, after save they are single Visits per customDate
        // customDates here are actually CustomDateInput not number from BE
        else if (visitOf.repeatModifier === VisitRepeatModifier.CustomDates) {
            if (visitOf.customDates && visitOf.customDates.length > 0) {
                const visitStartTime = DateTime.fromMillis(visitOf.startTime);
                const visitEndTime = DateTime.fromMillis(visitOf.endTime);

                let index = 0;

                for (const dateOf of visitOf.customDates) {
                    const start = DateTime.fromMillis(dateOf.timestamp || dateOf).set({
                        hour: visitStartTime.hour,
                        minute: visitStartTime.minute,
                        second: visitStartTime.second
                    });
                    const end = DateTime.fromMillis(dateOf.timestamp || dateOf).set({
                        hour: visitEndTime.hour,
                        minute: visitEndTime.minute,
                        second: visitEndTime.second
                    });

                    const dynamic: IVisitEvent = {
                        ...visitOf,
                        isDynamicChild: true,
                        dynamicId: `${visitOf.id}_${index}`,
                        startDateOfDynamic: start,
                        endDateOfDynamic: end,
                    };

                    combined.push(dynamic);

                    index++;
                }
            }
        } else {
            combined.push({
                ...visitOf,
                isDynamicChild: false,
            });
        }
    }

    for (const visitOf of repeating) {
        const visitStartTime = DateTime.fromMillis(visitOf.startTime);
        const visitEndTime = DateTime.fromMillis(visitOf.endTime);

        const instants = RepeatingVisitInstants(
            visitOf,
            visitRepeatDays.filter(jrd => jrd.visitId === visitOf.id),
            from,
            to,
            visitOf.repeatWeekDays,
        );

        let index = 0;

        for (const key of Object.keys(instants)) {
            const repeatingDay = parseInt(key);
            const instant = instants[repeatingDay];

            const visitRepeatDay = visitRepeatDays.find(jrd => jrd.repeatingDay === repeatingDay && jrd.visitId === visitOf.id);

            const start = visitRepeatDay ? DateTime.fromMillis(visitRepeatDay.startDate) : instant.set({
                hour: visitStartTime.hour,
                minute: visitStartTime.minute,
                second: visitStartTime.second
            });
            let end = visitRepeatDay ? DateTime.fromMillis(visitRepeatDay.endDate) : instant.set({
                hour: visitEndTime.hour,
                minute: visitEndTime.minute,
                second: visitEndTime.second
            });

            if (visitRepeatDay && visitRepeatDay.wholeDay) {
                end = start.endOf('day');
            }

            const dynamic: IVisitEvent = {
                ...visitOf,
                isDynamicChild: true,
                dynamicId: `${visitOf.id}_${index}`,
                startDateOfDynamic: start,
                endDateOfDynamic: end,
                repeatingDay,
                visitRepeatDay: visitRepeatDay,
            };

            const deleted = visitRepeatDay?.deleted || visitOf.deleted;

            if (!deleted) {
                combined.push(dynamic);
            }

            index++;
        }
    }

    if (multipleEventsForMultiDay) {
        const newCombined: IVisitEvent[] = [];

        for (const visitOf of combined) {
            const dateTimes = visitDateTimes(visitOf, visitOf.visitRepeatDay, visitOf.repeatingDay, visitOf);

            if (!dateTimes.isSingleDay && dateTimes.individualDays && dateTimes.individualDays.length >= 1) {
                for (const dayOf of dateTimes.individualDays) {
                    const start = dayOf.set({
                        hour: dateTimes.startTime.hour,
                        minute: dateTimes.startTime.minute,
                        second: dateTimes.startTime.second
                    });
                    const end = dayOf.set({
                        hour: dateTimes.endTime.hour,
                        minute: dateTimes.endTime.minute,
                        second: dateTimes.endTime.second
                    });

                    const visitOfCopy: IVisitEvent = {
                        ...visitOf,
                        isDynamicChild: true,
                        dynamicId: `${visitOf.id}_${dayOf.toMillis()}`,
                        startDateOfDynamic: start,
                        endDateOfDynamic: end,
                    };

                    if (start <= to && end >= from) {
                        newCombined.push(visitOfCopy);
                    }
                }
            } else {
                newCombined.push(visitOf);
            }
        }

        combined = newCombined;
    }

    return combined.sort((a, b) => {
        if (sortDesc) {
            if (a.startDateOfDynamic && b.startDateOfDynamic) {
                return b.startDateOfDynamic.toMillis() - a.startDateOfDynamic.toMillis();
            } else if (a.startDateOfDynamic) {
                return b.startDate - a.startDateOfDynamic.toMillis();
            } else if (b.startDateOfDynamic) {
                return b.startDateOfDynamic.toMillis() - a.startDate;
            }

            return b.startDate - a.startDate;
        } else {
            if (a.startDateOfDynamic && b.startDateOfDynamic) {
                return a.startDateOfDynamic.toMillis() - b.startDateOfDynamic.toMillis();
            } else if (a.startDateOfDynamic) {
                return a.startDateOfDynamic.toMillis() - b.startDate;
            } else if (b.startDateOfDynamic) {
                return a.startDate - b.startDateOfDynamic.toMillis();
            }

            return a.startDate - b.startDate;
        }
    });
}

/**
 * Process Visits to IVisitEvent for scheduleLater Visits.
 */
export function VisitsProcessScheduleLater(
    visits: VisitListPureResponse[],
): IVisitEvent[] {
    return visits
        .filter(visit => visit.status === VisitStatus.ScheduleLater)
        .map(visit => {
            return {
                ...visit,
                isDynamicChild: false,
            };
        });
}

export interface IVisitsDay {
    date: DateTime;
    visits: IVisitEvent[];
}

/**
 * Process IVisitEvent[] to IVisitsDay[].
 */
export function ProcessVisitsEventsToDays(
    events: IVisitEvent[],
    sortDesc?: boolean,
): IVisitsDay[] {
    const days: IVisitsDay[] = [];

    events.forEach(event => {
        const startDate = DateTime.fromMillis(event.visitRepeatDay?.startDate || event.startDateOfDynamic?.toMillis() || event.startDate).startOf('day');

        const day = days.find(d => d.date.toMillis() == startDate.toMillis());

        if (day) {
            day.visits.push(event);
        } else {
            days.push({date: startDate, visits: [event]});
        }
    });

    if (sortDesc) {
        days.sort((a, b) => b.date.toMillis() - a.date.toMillis());
    } else {
        days.sort((a, b) => a.date.toMillis() - b.date.toMillis());
    }

    return days;
}

/**
 * Convert Visits into Fullcalendar Events.
 * This does not handle repeating, repeating must be already processed from dynamic Visits.
 */
export function VisitsToEvents(
    visits: IVisitEvent[],
    theme: Theme,
    onlyName?: boolean,
    clients?: ClientPureResponse[] | NullOrUndefined,
    currentVisit?: boolean,
    hasUpdatePermission?: boolean,
): EventInput[] {
    return visits.map(visit => {
        const resourceIds = visit.visitRepeatDay ? visit.visitRepeatDay.employeeIds.map(employeeId => `${employeeId}`) : visit.employeeIds.map(employeeId => `${employeeId}`);

        const status = visit.visitRepeatDay?.status || visit.status;
        let statusModifier = visit.visitRepeatDay?.statusModifier || visit.statusModifier;
        if (statusModifier === VisitStatusModifier.None) {
            statusModifier = undefined;
        }

        const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit, true);

        let textColor;
        let backgroundColor;
        let borderColor;

        let statusModifierClass = '';

        if (status === VisitStatus.Closed) {
            textColor = statusModifier ? getVisitStatusModifierColor(statusModifier) : kAppColors.text.primary(theme.palette.mode === "dark");
            backgroundColor = kVisitStatusColors.closed.background(theme.palette.mode === "dark");
            borderColor = statusModifier ? getVisitStatusModifierColor(statusModifier) : getVisitStatusTextColor(status, theme.palette.mode === "dark");
            statusModifierClass = statusModifier ? statusModifier.valueOf() : '';
        } else {
            textColor = getVisitStatusTextColor(status, theme.palette.mode === "dark");
            backgroundColor = getVisitStatusBackgroundColor(status, theme.palette.mode === "dark");
            borderColor = status === VisitStatus.Canceled ? getVisitStatusTextColor(status, theme.palette.mode === "dark") : 'transparent';
        }

        let title = onlyName ? (visit.name || '') : VisitNameOrSequenceId(visit, visit.visitRepeatDay);

        if (clients) {
            const client = clients.find(client => client.id === visit.clientId);

            if (client) {
                title = `${title == '' ? '' : `${title} · `}${client.name}`;
            }
        }

        const isPast = dateTimes.end!.toMillis() < DateTime.now().toMillis();

        const canEdit = status !== VisitStatus.Canceled && status !== VisitStatus.Closed && hasUpdatePermission;

        return {
            id: visit.dynamicId || visit.id,
            title: title,
            start: dateTimes.start.toMillis(),
            end: dateTimes.end!.toMillis(),
            textColor: textColor,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            extendedProps: {
                visit: visit,
                currentVisit: currentVisit,
            },
            resourceIds: resourceIds.length > 0 ? resourceIds : ['-1'],
            classNames: [
                `${status.valueOf()} ${visit.repeating != 'Never' ? 'repeatingJob' : 'nonRepeatingJob'} ${statusModifierClass}`,
                `job-status-${status.valueOf()}-${theme.palette.mode}`,
                currentVisit ? 'current-visit' : '',
                isPast ? 'is-past-event' : '',
                !canEdit ? 'not-editable' : ''
            ],
            allDay: visit.visitRepeatDay ? visit.visitRepeatDay.wholeDay : visit.wholeDay,
            editable: canEdit,
        };
    });
}

/**
 * Sort Visits for Fullcalendar.
 */
export function VisitsSortFullcalendar(
    a: any,
    b: any,
): number {
    if (a.extendedProps?.currentVisit) {
        return -1;
    } else if (b.extendedProps?.currentVisit) {
        return 1;
    }

    const aStart = a.start as number;
    const bStart = b.start as number;

    return aStart - bStart;
}

export interface IVisitDateTimes {
    start: DateTime;
    end: DateTime | NullOrUndefined;
    visitEnd: DateTime | NullOrUndefined;
    startTime: DateTime;
    endTime: DateTime;
    isSingleDay: boolean;
    wholeDay: boolean;
    individualDays?: DateTime[];
}

/**
 * Get correct start and end date for all Visits.
 * Should work over Timezone changes.
 * And should be able to handle DSTs changes.
 */
export function visitDateTimes(
    visit?: VisitResponse | VisitListPureResponse | NullOrUndefined,
    visitRepeatDay?: VisitRepeatDayResponse | VisitRepeatDayListPureResponse | NullOrUndefined,
    repeatingDay?: number,
    visitEvent?: IVisitEvent | NullOrUndefined,
    fakeForCalendar?: boolean,
): IVisitDateTimes {
    let start: DateTime = DateTime.now();
    let end: DateTime | NullOrUndefined = undefined;
    let visitEnd: DateTime | NullOrUndefined = undefined;
    let startTime: DateTime = DateTime.now();
    let endTime: DateTime = DateTime.now();
    let isSingleDay = true;
    let individualDays: DateTime[] | undefined = undefined;

    if (visit) {
        start = DateTime.fromMillis(visit.startDate);
        end = visit.endDate ? DateTime.fromMillis(visit.endDate) : null;
        visitEnd = visit.repeatEndDate ? DateTime.fromMillis(visit.repeatEndDate) : null;

        startTime = DateTime.fromMillis(visit.startTime);
        endTime = DateTime.fromMillis(visit.endTime);

        isSingleDay = end && (visit.repeating === VisitRepeating.Never || !visit.repeating) ? start.hasSame(end, 'day') : true;
        const wholeDay = visitRepeatDay ? visitRepeatDay.wholeDay : visit.wholeDay;

        if (visitEvent && visitEvent.isDynamicChild) {
            start = visitEvent.startDateOfDynamic!;
            end = visitEvent.endDateOfDynamic!;
        } else if (visitRepeatDay) {
            // startTime = DateTime.fromMillis(visitRepeatDay.startTime);
            // endTime = DateTime.fromMillis(visitRepeatDay.endTime);
            startTime = DateTime.fromMillis(visitRepeatDay.startDate);
            endTime = DateTime.fromMillis(visitRepeatDay.endDate);

            start = DateTime.fromMillis(visitRepeatDay.startDate);
            end = DateTime.fromMillis(visitRepeatDay.endDate);

            if (visitRepeatDay && visitRepeatDay.wholeDay) {
                end = start.endOf("day");
            }
        } else if (repeatingDay) {
            const theDate = RepeatingDayIdToDateTime(repeatingDay, startTime.toMillis());

            start = theDate.set({
                hour: startTime.hour,
                minute: startTime.minute,
                second: startTime.second
            });
            end = theDate.set({
                hour: endTime.hour,
                minute: endTime.minute,
                second: endTime.second
            });
        }

        let endBeforeWholeDay: DateTime | NullOrUndefined = end;

        if (!isSingleDay && wholeDay && fakeForCalendar) {
            start = start.startOf('day');
            // we fake next day as end so that calendar shows correctly on multipleDay nonRepeating visits
            endBeforeWholeDay = end?.endOf('day');
            end = end?.endOf('day').plus({milliseconds: 1});
        }

        if (!isSingleDay && start && endBeforeWholeDay) {
            individualDays = [];
            const skipDays = visit.skipDays.map(skipDay => DateTime.fromMillis(skipDay));

            let nextDate = start;
            while (nextDate <= endBeforeWholeDay) {
                const isSkipDay = skipDays.find(skipDay => skipDay.hasSame(nextDate, 'day'));
                const isSkipWeekend = (nextDate.weekday === 6 || nextDate.weekday === 7) && visit.repeatModifier === VisitRepeatModifier.SkipWeekends;

                if (!isSkipDay && !isSkipWeekend) {
                    individualDays.push(nextDate);
                }
                nextDate = nextDate.plus({days: 1});
            }
        }
    }

    return {
        start,
        end,
        visitEnd,
        startTime,
        endTime,
        isSingleDay,
        wholeDay: (visitRepeatDay ? visitRepeatDay.wholeDay : visit?.wholeDay) || false,
        individualDays,
    };
}

/**
 * Calculate all visit dates on a specific interval from of a target repeating visit.
 *
 * Calculation is completely unoptimized. Takes [startDate] and iterate through [nextVisitRepeatDate] until all dates are
 * retrieved for a specified interval.
 */
export function RepeatingVisitInstants(
    visit: VisitListPureResponse,
    visitRepeatDays: VisitRepeatDayListPureResponse[],
    from: DateTime,
    to: DateTime,
    repeatWeekDays?: number[],
): Record<number, DateTime> {
    const instants: Record<number, DateTime> = {};
    const processedRepeatingDays: number[] = [];

    if (visit.repeating == VisitRepeating.None || visit.repeating == VisitRepeating.Never) {
        return {};
    }

    const visitStartDate = DateTime.fromMillis(visit.startDate);
    const endDateInstant = visit.endDate ? DateTime.fromMillis(visit.endDate) : null;
    let repeatEndDateInstant = visit.repeatEndDate ? DateTime.fromMillis(visit.repeatEndDate) : null;
    if (repeatEndDateInstant) {
        repeatEndDateInstant = repeatEndDateInstant.endOf('day');
    }

    let nextDate: DateTime | null = DateTime.fromMillis(visit.startDate);

    if (visit.repeating === VisitRepeating.Monthly) {
        const monthDays = visit.monthDays || [];
        const monthWeekDays = visit.monthWeekDays || [];

        const validDayOfWeekParameters: IDayOfWeekPositionInMonth[] = monthWeekDays.map(dayOfWeek => {
            const dayOfWeekSplit = dayOfWeek.split('-');

            return {
                positionInMonth: parseInt(dayOfWeekSplit[0]),
                dayOfWeek: parseInt(dayOfWeekSplit[1]),
            };
        });

        if (monthDays.length > 0 || monthWeekDays.length > 0) {
            const daysOfAllMonthsWithinInterval: DateTime[] = [];
            const lastDaysOfAllMonthsWithinInterval: DateTime[] = [];

            const startTime = DateTime.fromMillis(visit.startTime);
            let nextMonth = from.startOf('month');

            while (nextMonth <= to) {
                const daysOfThisMonth: DateTime[] = [];

                if (visit.repeatModifier === VisitRepeatModifier.DayOfMonth || visit.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay || visit.repeatModifier === VisitRepeatModifier.LastDayOfMonth) {
                    daysOfThisMonth.push(
                        ...monthDays
                            .filter(dayOfMonth => dayOfMonth > 0)
                            .map(dayOfMonth => {
                                return nextMonth.set({
                                    day: dayOfMonth,
                                    hour: startTime.hour,
                                    minute: startTime.minute,
                                    second: startTime.second,
                                });
                            })
                    );
                }

                if (visit.repeatModifier === VisitRepeatModifier.DayOfWeek || visit.repeatModifier === VisitRepeatModifier.DayOfWeekOrLastDay) {
                    for (let dayInNextMonth = 1; dayInNextMonth <= nextMonth.daysInMonth!; dayInNextMonth++) {
                        const checkingDate = nextMonth.set({
                            day: dayInNextMonth,
                            hour: startTime.hour,
                            minute: startTime.minute,
                            second: startTime.second,
                        });

                        const nextDayOfWeekPosition = GetDayOfWeekPositionInMonth(
                            checkingDate
                        );

                        const isValidDayOfWeek = validDayOfWeekParameters.find(validDayOfWeekParameter => {
                            return validDayOfWeekParameter.dayOfWeek === nextDayOfWeekPosition.dayOfWeek && validDayOfWeekParameter.positionInMonth === nextDayOfWeekPosition.positionInMonth;
                        });

                        if (isValidDayOfWeek) {
                            daysOfThisMonth.push(checkingDate);
                        }
                    }
                }

                daysOfAllMonthsWithinInterval.push(...daysOfThisMonth);

                const lastDayOfMonth = nextMonth.endOf('month');

                lastDaysOfAllMonthsWithinInterval.push(lastDayOfMonth.set({
                    hour: startTime.hour,
                    minute: startTime.minute,
                    second: startTime.second,
                }));

                nextMonth = nextMonth.plus({months: visit.every || 1});
            }

            if (daysOfAllMonthsWithinInterval.length > 0 && (visit.repeatModifier === VisitRepeatModifier.DayOfMonth || visit.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay || visit.repeatModifier === VisitRepeatModifier.DayOfWeek || visit.repeatModifier === VisitRepeatModifier.DayOfWeekOrLastDay)) {
                for (const dayOf of daysOfAllMonthsWithinInterval) {
                    const theRepeatingDay = DateTimeToRepeatingDayId(dayOf);
                    const visitRepeatDay = visitRepeatDays.find(jrd => jrd.repeatingDay === theRepeatingDay);
                    let filterBy = dayOf;

                    if (visitRepeatDay) {
                        filterBy = DateTime.fromMillis(visitRepeatDay.startDate);
                    }

                    if (!(filterBy < from) && !(filterBy > to) && (!endDateInstant || !(filterBy > endDateInstant)) && (!repeatEndDateInstant || !(filterBy > repeatEndDateInstant))) {
                        if (!processedRepeatingDays.includes(theRepeatingDay)) {
                            processedRepeatingDays.push(theRepeatingDay);
                            instants[theRepeatingDay] = dayOf;
                        }
                    }
                }
            }

            if (lastDaysOfAllMonthsWithinInterval.length > 0 && (visit.repeatModifier === VisitRepeatModifier.LastDayOfMonth || visit.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay)) {
                for (const dayOf of lastDaysOfAllMonthsWithinInterval) {
                    const theRepeatingDay = DateTimeToRepeatingDayId(dayOf);
                    const visitRepeatDay = visitRepeatDays.find(jrd => jrd.repeatingDay === theRepeatingDay);
                    let filterBy = dayOf;

                    if (visitRepeatDay) {
                        filterBy = DateTime.fromMillis(visitRepeatDay.startDate);
                    }

                    if (!(filterBy < from) && !(filterBy > to) && (!endDateInstant || !(filterBy > endDateInstant)) && (!repeatEndDateInstant || !(filterBy > repeatEndDateInstant))) {
                        if (!processedRepeatingDays.includes(theRepeatingDay)) {
                            processedRepeatingDays.push(theRepeatingDay);
                            instants[theRepeatingDay] = dayOf;
                        }
                    }
                }
            }
        }
    } else {
        while (nextDate && nextDate <= to && (!endDateInstant || !(nextDate > endDateInstant)) && (!repeatEndDateInstant || !(nextDate > repeatEndDateInstant))) {
            if (visit.repeating === VisitRepeating.Daily && visit.repeatModifier === VisitRepeatModifier.SkipWeekends) {
                const isWeekend = nextDate.weekday === 6 || nextDate.weekday === 7;

                if (isWeekend) {
                    nextDate = NextVisitRepeat(visit, nextDate);
                    continue;
                }
            }

            if (visit.repeating === VisitRepeating.Weekly && repeatWeekDays && repeatWeekDays.length > 0) {
                for (const weekDayOf of repeatWeekDays) {
                    let dayOfWeek = nextDate.set({weekday: weekDayOf as any});
                    const theRepeatingDay = DateTimeToRepeatingDayId(dayOfWeek);
                    let filterBy = dayOfWeek;

                    const visitRepeatDay = visitRepeatDays.find(jrd => jrd.repeatingDay === theRepeatingDay);

                    if (visitRepeatDay) {
                        filterBy = DateTime.fromMillis(visitRepeatDay.startDate);
                    }

                    if (!(filterBy < from) && !(filterBy > to) && !(filterBy < visitStartDate) && (!endDateInstant || !(filterBy > endDateInstant)) && (!repeatEndDateInstant || !(filterBy > repeatEndDateInstant))) {
                        if (!processedRepeatingDays.includes(theRepeatingDay)) {
                            processedRepeatingDays.push(theRepeatingDay);
                            instants[theRepeatingDay] = dayOfWeek;
                        }
                    }
                }
            } else {
                const theRepeatingDay = DateTimeToRepeatingDayId(nextDate!);
                const visitRepeatDay = visitRepeatDays.find(jrd => jrd.repeatingDay === theRepeatingDay);
                let filterBy = nextDate;

                if (visitRepeatDay) {
                    filterBy = DateTime.fromMillis(visitRepeatDay.startDate);
                }

                if (!(filterBy < from) && !(filterBy > to) && (!endDateInstant || !(filterBy > endDateInstant)) && (!repeatEndDateInstant || !(filterBy > repeatEndDateInstant))) {
                    if (!processedRepeatingDays.includes(theRepeatingDay)) {
                        processedRepeatingDays.push(theRepeatingDay);
                        instants[theRepeatingDay] = nextDate;
                    }
                }
            }

            nextDate = NextVisitRepeat(visit, nextDate);
        }
    }

    for (let visitRepeatDayOf of visitRepeatDays) {
        const theRepeatingDay: number = visitRepeatDayOf.repeatingDay;

        if (!processedRepeatingDays.includes(theRepeatingDay)) {
            processedRepeatingDays.push(theRepeatingDay);

            const filterBy = DateTime.fromMillis(visitRepeatDayOf.startDate);

            if (!(filterBy < from) && !(filterBy > to) && !visitRepeatDayOf.ended) {
                instants[theRepeatingDay] = filterBy;
            }
        }
    }

    return instants;
}

/**
 * Calculation of the next visit date repetition.
 *
 * Returns nonnull result only for repeatable visits.
 * Returns [null] when visit is not repeatable or nextDate would be after end date of the visit.
 */
export function NextVisitRepeat(
    visit: VisitListPureResponse,
    from: DateTime,
): DateTime | null {
    if (visit.repeating == VisitRepeating.None || visit.repeating == VisitRepeating.Never) {
        return null;
    }

    if (visit.repeating === VisitRepeating.Monthly) {
        console.error('NextJobRepeat: visit.repeating === VisitRepeating.Monthly is not supported');
        return null;
    }

    const endDateInstant = visit.endDate ? DateTime.fromMillis(visit.endDate) : null;
    const repeatEndDateInstant = visit.repeatEndDate ? DateTime.fromMillis(visit.repeatEndDate) : null;
    const isRequestNextDateAfterEndOfVisitRepeating = (endDateInstant && from > endDateInstant) || (repeatEndDateInstant && from > repeatEndDateInstant);

    if (isRequestNextDateAfterEndOfVisitRepeating) {
        return null;
    }

    let nextDateCandidate = DateTimePlusVisitRepeating(
        from,
        visit.repeating,
        visit.every!,
    );

    if (visit.repeating === VisitRepeating.Weekly) {
        nextDateCandidate = nextDateCandidate.set({weekday: 1});
    }

    while (nextDateCandidate < from && (!endDateInstant || !(nextDateCandidate > endDateInstant)) && (!repeatEndDateInstant || !(nextDateCandidate > repeatEndDateInstant))) {
        nextDateCandidate = DateTimePlusVisitRepeating(
            nextDateCandidate,
            visit.repeating,
            visit.every!,
        );
    }

    return nextDateCandidate;
}

export const kStatusFilters = [
    VisitStatus.Unfinished,
    VisitStatus.ScheduleLater,
    VisitStatus.JobOffer,
    VisitStatus.Scheduled,
    VisitStatus.Travelling,
    VisitStatus.InProgress,
    VisitStatus.Done,
];

export const kStatusModifierFilters = [
    VisitStatusModifier.Invoiced,
    VisitStatusModifier.Paid,
    VisitStatusModifier.NotPaid,
];

export function getVisitStatusModifierTitle(status: VisitStatusModifier) {
    switch (status) {
        case VisitStatusModifier.None:
            return tt('dashboard.jobStatusModifier.none');
        case VisitStatusModifier.Invoiced:
            return tt('dashboard.jobStatusModifier.invoiced');
        case VisitStatusModifier.Paid:
            return tt('dashboard.jobStatusModifier.paid');
        case VisitStatusModifier.NotPaid:
            return tt('dashboard.jobStatusModifier.notPaid');
    }
}

export function getVisitStatusModifierIcon(status: VisitStatusModifier) {
    switch (status) {
        case VisitStatusModifier.None:
            return <></>;
        case VisitStatusModifier.Invoiced:
            return <Icons8Invoice/>;
        case VisitStatusModifier.Paid:
            return <Icons8InvoicePaid/>;
        case VisitStatusModifier.NotPaid:
            return <Icons8InvoiceNotPaid/>;
    }
}

export function getVisitStatusTitle(status: VisitStatus) {
    switch (status) {
        case VisitStatus.JobOffer:
            return tt('dashboard.joblistStatus.offer');
        case VisitStatus.ScheduleLater:
            return tt('dashboard.joblistStatus.scheduleLater');
        case VisitStatus.Scheduled:
            return tt('dashboard.joblistStatus.scheduled');
        case VisitStatus.Travelling:
            return tt('dashboard.joblistStatus.onWay');
        case VisitStatus.InProgress:
            return tt('dashboard.joblistStatus.inProgress');
        case VisitStatus.Done:
            return tt('dashboard.joblistStatus.done');
        case VisitStatus.Unfinished:
            return tt('dashboard.joblistStatus.notArrived');
        case VisitStatus.Canceled:
            return tt('dashboard.joblistStatus.canceled');
        case VisitStatus.Closed:
            return tt('dashboard.joblistStatus.closed');
    }
}

export function getEmployeeJobStatusTitle(status: JobEmployeeStatus, editStatus?: boolean) {
    switch (status) {
        case JobEmployeeStatus.JobOffer:
            return tt('dashboard.joblistStatus.offer');
        case JobEmployeeStatus.ScheduleLater:
            return tt('dashboard.joblistStatus.scheduleLater');
        case JobEmployeeStatus.Scheduled:
            if (editStatus) {
                return tt('editVisitStatusModal.status.scheduled');
            }
            return tt('dashboard.joblistStatus.scheduled');
        case JobEmployeeStatus.Travelling:
            return tt('dashboard.joblistStatus.onWay');
        case JobEmployeeStatus.InProgress:
            if (editStatus) {
                return tt('editVisitStatusModal.status.inProgress');
            }
            return tt('dashboard.joblistStatus.inProgress');
        case JobEmployeeStatus.Done:
            if (editStatus) {
                return tt('editVisitStatusModal.status.done');
            }
            return tt('dashboard.joblistStatus.done');
        case JobEmployeeStatus.NotArrived:
            if (editStatus) {
                return tt('editVisitStatusModal.status.notArrived');
            }
            return tt('dashboard.joblistStatus.notArrived');
        case JobEmployeeStatus.CanceledByManager:
            if (editStatus) {
                return tt('editVisitStatusModal.status.canceledByManager');
            }
            return tt('dashboard.joblistStatus.canceled');
        case JobEmployeeStatus.CanceledByWorker:
            if (editStatus) {
                return tt('editVisitStatusModal.status.canceledByWorker');
            }
            return tt('dashboard.joblistStatus.canceled');
    }

    return '';
}

export function getVisitStatusModifierColor(status: VisitStatusModifier) {
    switch (status) {
        case VisitStatusModifier.Invoiced:
            return kVisitStatusModifiersColors.invoiced;
        case VisitStatusModifier.Paid:
            return kVisitStatusModifiersColors.paid;
        case VisitStatusModifier.NotPaid:
            return kVisitStatusModifiersColors.notPaid;
    }
}

export function getVisitStatusTextColor(status: VisitStatus, isDarkMode: boolean) {
    switch (status) {
        case VisitStatus.JobOffer:
            return kVisitStatusColors.offer.text(isDarkMode);
        case VisitStatus.ScheduleLater:
            return kVisitStatusColors.scheduleLater.text;
        case VisitStatus.Scheduled:
            return kVisitStatusColors.scheduled.text;
        case VisitStatus.Travelling:
            return kVisitStatusColors.travelling.text;
        case VisitStatus.InProgress:
            return kVisitStatusColors.inProgress.text;
        case VisitStatus.Done:
            return kVisitStatusColors.done.text;
        case VisitStatus.Closed:
            return kVisitStatusColors.closed.text(isDarkMode);
        case VisitStatus.Unfinished:
            return kVisitStatusColors.unfinished.text;
        case VisitStatus.Canceled:
            return kVisitStatusColors.canceled.text(isDarkMode);
    }
}

export function getEmployeeJobStatusTextColor(status: JobEmployeeStatus, isDarkMode: boolean) {
    switch (status) {
        case JobEmployeeStatus.JobOffer:
            return kVisitStatusColors.offer.text(isDarkMode);
        case JobEmployeeStatus.ScheduleLater:
            return kVisitStatusColors.scheduleLater.text;
        case JobEmployeeStatus.Scheduled:
            return kVisitStatusColors.scheduled.text;
        case JobEmployeeStatus.Travelling:
            return kVisitStatusColors.travelling.text;
        case JobEmployeeStatus.InProgress:
            return kVisitStatusColors.inProgress.text;
        case JobEmployeeStatus.Done:
            return kVisitStatusColors.done.text;
        case JobEmployeeStatus.NotArrived:
            return kVisitStatusColors.unfinished.text;
        case JobEmployeeStatus.CanceledByManager:
        case JobEmployeeStatus.CanceledByWorker:
            return kVisitStatusColors.canceled.text(isDarkMode);
    }
}

export function getVisitStatusBackgroundColor(status: VisitStatus, isDarkMode: boolean) {
    switch (status) {
        case VisitStatus.JobOffer:
            return kVisitStatusColors.offer.background(isDarkMode);
        case VisitStatus.ScheduleLater:
            return kVisitStatusColors.scheduleLater.background(isDarkMode);
        case VisitStatus.Scheduled:
            return kVisitStatusColors.scheduled.background(isDarkMode);
        case VisitStatus.Travelling:
            return kVisitStatusColors.travelling.background(isDarkMode);
        case VisitStatus.InProgress:
            return kVisitStatusColors.inProgress.background(isDarkMode);
        case VisitStatus.Done:
            return kVisitStatusColors.done.background(isDarkMode);
        case VisitStatus.Closed:
            return kVisitStatusColors.closed.background(isDarkMode);
        case VisitStatus.Unfinished:
            return kVisitStatusColors.unfinished.background(isDarkMode);
        case VisitStatus.Canceled:
            return kVisitStatusColors.canceled.background(isDarkMode);
    }
}

export function getEmployeeJobStatusBackgroundColor(status: JobEmployeeStatus, isDarkMode: boolean) {
    switch (status) {
        case JobEmployeeStatus.JobOffer:
            return kVisitStatusColors.offer.background(isDarkMode);
        case JobEmployeeStatus.ScheduleLater:
            return kVisitStatusColors.scheduleLater.background(isDarkMode);
        case JobEmployeeStatus.Scheduled:
            return kVisitStatusColors.scheduled.background(isDarkMode);
        case JobEmployeeStatus.Travelling:
            return kVisitStatusColors.travelling.background(isDarkMode);
        case JobEmployeeStatus.InProgress:
            return kVisitStatusColors.inProgress.background(isDarkMode);
        case JobEmployeeStatus.Done:
            return kVisitStatusColors.done.background(isDarkMode);
        case JobEmployeeStatus.NotArrived:
            return kVisitStatusColors.unfinished.background(isDarkMode);
        case JobEmployeeStatus.CanceledByManager:
        case JobEmployeeStatus.CanceledByWorker:
            return kVisitStatusColors.canceled.background(isDarkMode);
    }
}

export function getVisitStatusIcon(status: VisitStatus) {
    switch (status) {
        case VisitStatus.ScheduleLater:
            return <Icons8CalendarWithQuestionMark/>;
        case VisitStatus.Scheduled:
            return <Icons8CalendarWithCheckMark/>;
        case VisitStatus.Travelling:
            return <Icons8InTransit/>;
        case VisitStatus.InProgress:
            return <HammerIcon/>;
        case VisitStatus.Done:
            return <Icons8CheckInCircle/>;
        case VisitStatus.Unfinished:
            return <Icons8MediumRisk/>;
        case VisitStatus.Canceled:
            return <Icons8Cancel2/>;
        case VisitStatus.JobOffer:
            return <Icons8Reseller/>;
        case VisitStatus.Closed:
            return <Icons8LockOutlined/>;
    }
}

export function getEmployeeJobStatusIcon(status: JobEmployeeStatus) {
    switch (status) {
        case JobEmployeeStatus.ScheduleLater:
            return <Icons8SandWatch/>;
        case JobEmployeeStatus.Scheduled:
            return <Icons8CalendarWithCheckMark/>;
        case JobEmployeeStatus.Travelling:
            return <Icons8InTransit/>;
        case JobEmployeeStatus.InProgress:
            return <HammerIcon/>;
        case JobEmployeeStatus.Done:
            return <Icons8Ok/>;
        case JobEmployeeStatus.NotArrived:
            return <Icons8MediumRisk/>;
        case JobEmployeeStatus.CanceledByManager:
        case JobEmployeeStatus.CanceledByWorker:
            return <Icons8Cancel2/>;
    }
}

/**
 * Format Visit start and end into single line text.
 * Uses VisitRepeatDay if available.
 */
export function VisitSingleLineTimes(visit: VisitResponse, visitRepeatDay?: VisitRepeatDayResponse) {
    const startTime = visitRepeatDay?.startTime || visit.startTime;
    const endTime = visitRepeatDay?.endTime || visit.endTime;

    const start = DateTime.fromMillis(startTime);
    const end = DateTime.fromMillis(endTime);

    if (startTime === endTime) {
        return start.toFormat('H:mm');
    }

    return `${start.toFormat('H:mm')} - ${end.toFormat('H:mm')}`;
}

/**
 * Display Visit formatted date that respects dynamic repeat.
 * Uses VisitRepeatDay if available.
 */
export function VisitDateWithDynamic(visit: IVisitEvent, visitRepeatDay?: VisitRepeatDayResponse | VisitRepeatDayListPureResponse, showIfIterationHasMoreThenDay?: boolean) {
    const startDate = visitRepeatDay?.startDate || visit.startDateOfDynamic?.toMillis() || visit.startDate;

    let text = DateTime.fromMillis(startDate).toFormat('d.M.yyyy');

    if (showIfIterationHasMoreThenDay) {
        if (visitRepeatDay?.startDate && visitRepeatDay?.endDate) {
            const start = DateTime.fromMillis(visitRepeatDay.startDate);
            const end = DateTime.fromMillis(visitRepeatDay.endDate);

            if (!start.hasSame(end, 'day')) {
                text += ` - ${end.toFormat('d.M.yyyy')}`;
            }
        } else if (visit.startDateOfDynamic && visit.endDateOfDynamic) {
            const start = visit.startDateOfDynamic;
            const end = visit.endDateOfDynamic;

            if (!start.hasSame(end, 'day')) {
                text += ` - ${end.toFormat('d.M.yyyy')}`;
            }
        } else if (visit.startDate && visit.endDate) {
            const start = DateTime.fromMillis(visit.startDate);
            const end = DateTime.fromMillis(visit.endDate);

            if (!start.hasSame(end, 'day')) {
                text += ` - ${end.toFormat('d.M.yyyy')}`;
            }
        }
    }

    return text;
}

/**
 * Display Visit name or use only sequenceId.
 * Uses VisitRepeatDay if available.
 */
export function VisitNameOrSequenceId(visit: VisitResponse | VisitListPureResponse, visitRepeatDay?: VisitRepeatDayResponse | VisitRepeatDayListPureResponse | NullOrUndefined): string {
    if (visitRepeatDay?.name) {
        // return `#${visit.sequenceId} · ${visitRepeatDay.name}`;
        return visitRepeatDay.name;
    }

    if (visit.name) {
        // return `#${visit.sequenceId} · ${visit.name}`;
        return visit.name;
    }

    // return `#${visit.sequenceId}`;
    return '';
}

/**
 * Convert DateTime into repeatingDay id timestamp which is in utc.
 */
export function DateTimeToRepeatingDayId(date: DateTime): number {
    return date.toUTC().startOf('day').toMillis();
}

/**
 * Convert to DateTime from repeatingDay id timestamp which is in utc.
 */
export function RepeatingDayIdToDateTime(repeatingDayId: number, startTime: number): DateTime {
    let dateTime = DateTime.fromMillis(repeatingDayId, {
        zone: 'utc',
    });

    const startTimeAtUTC = DateTime.fromMillis(startTime, {
        zone: 'utc',
    });

    dateTime = dateTime.set({
        hour: startTimeAtUTC.hour,
        minute: startTimeAtUTC.minute,
        second: startTimeAtUTC.second,
    });

    return dateTime.toLocal().startOf('day');
}

export interface IFilterJobEmployeeDataParams {
    jobEmployeeData: JobEmployeeDataResponse[] | NullOrUndefined;
    repeatingDay?: number;
    filterDistinctByRepeatingDay?: boolean;
    filterByEmployeeId?: number;
    filterByEmployeeIds?: number[];
    filterByVisitId?: number;
}

/**
 * Filter JobEmployeeData items for Visit.
 * Filtered by repeatingDay also removes null repeatingDay for those that have repeatingDay.
 */
export function filterJobEmployeeData(params: IFilterJobEmployeeDataParams): JobEmployeeDataResponse[] {
    const {
        jobEmployeeData,
        repeatingDay,
        filterDistinctByRepeatingDay = true,
        filterByEmployeeId,
        filterByEmployeeIds,
        filterByVisitId,
    } = params;

    if (jobEmployeeData) {
        let processing = jobEmployeeData
            .filter((item) => {
                return !item.repeatingDay || item.repeatingDay === repeatingDay;
            });

        if (filterByVisitId) {
            processing = processing.filter((item) => item.visitId === filterByVisitId);
        }

        if (filterByEmployeeId) {
            processing = processing.filter((item) => item.employeeId === filterByEmployeeId);
        }

        if (filterByEmployeeIds) {
            processing = processing.filter((item) => filterByEmployeeIds.includes(item.employeeId));
        }

        if (filterDistinctByRepeatingDay && repeatingDay) {
            processing = processing.filter((item) => {
                if (!item.repeatingDay) {
                    const repeatDayData = processing.find((otherData) => {
                        return otherData.repeatingDay === repeatingDay && item.employeeId === otherData.employeeId;
                    });

                    if (repeatDayData) {
                        return false;
                    }
                }

                return true;
            });
        }

        return processing;
    }

    return [];
}

export interface IFilterJobEmployeeTimesheetItemsProps {
    jobEmployeeTimesheetItems: JobEmployeeTimesheetItemResponse[] | NullOrUndefined;
    repeatingDay?: number;
    filterDistinctByRepeatingDay?: boolean;
    filterByDeleted?: boolean;
    filterByEmployeeId?: number;
    filterByEmployeeIds?: number[];
    filterByVisitId?: number;
}

/**
 * Filter JobEmployeeTimesheetItems for not deleted and by repeatingDay.
 */
export function FilterJobEmployeeTimesheetItems(props: IFilterJobEmployeeTimesheetItemsProps): JobEmployeeTimesheetItemResponse[] {
    const {
        jobEmployeeTimesheetItems,
        repeatingDay,
        filterDistinctByRepeatingDay = true,
        filterByDeleted = true,
        filterByEmployeeId,
        filterByEmployeeIds,
        filterByVisitId,
    } = props;

    if (jobEmployeeTimesheetItems) {
        let processing = jobEmployeeTimesheetItems
            .filter((item) => {
                if (filterByVisitId && item.visitId !== filterByVisitId) {
                    return false;
                }

                if (filterByEmployeeId && item.employeeId !== filterByEmployeeId) {
                    return false;
                }

                if (filterByEmployeeIds && !filterByEmployeeIds.includes(item.employeeId)) {
                    return false;
                }

                if (!(!item.repeatingDay || item.repeatingDay === repeatingDay)) {
                    return false;
                }

                if (filterDistinctByRepeatingDay && repeatingDay) {
                    if (!item.repeatingDay) {
                        const repeatDayData = jobEmployeeTimesheetItems.find((otherData) => {
                            return otherData.repeatingDay === repeatingDay && item.visitId === otherData.visitId && item.uuid === otherData.uuid && item.employeeId === otherData.employeeId;
                        });

                        if (repeatDayData) {
                            return false;
                        }
                    }
                }

                return true;
            });

        if (filterByDeleted) {
            processing = processing.filter((item) => !item.deleted);
        }

        return processing;
    }

    return [];
}

/**
 * Convert VisitRepeating to string.
 */
export function VisitRepeatingToString(repeating: VisitRepeating | Maybe<VisitRepeating> | undefined): string {
    switch (repeating) {
        case VisitRepeating.Weekly:
            return tt('common.weekly');
        case VisitRepeating.Monthly:
            return tt('common.monthly');
        case VisitRepeating.Yearly:
            return tt('common.yearly');
        case VisitRepeating.Daily:
            return tt('common.daily');
        default:
            return '';
    }
}

/**
 * Find all JobOfferSeatResponse[] for this Visit.
 * Also filters for repeating Visit repeatingDay.
 */
export function filterJobOfferSeatsForVisit(visit: IVisitEvent, visitOfferSeats: JobOfferSeatResponse[] | NullOrUndefined): JobOfferSeatResponse[] | undefined {
    if (visitOfferSeats) {
        return visitOfferSeats
            .filter((offerSeat) => offerSeat.visitId == visit.id)
            .filter((offerSeat) => {
                if (visit.repeatingDay && !offerSeat.repeatingDay) {
                    const repeatingDayData = visitOfferSeats.find((otherData) => {
                        return otherData.repeatingDay === visit.repeatingDay &&
                            otherData.visitId === visit.id &&
                            otherData.uuid === offerSeat.uuid;
                    });

                    if (repeatingDayData) {
                        return false;
                    }
                } else if (visit.repeatingDay && offerSeat.repeatingDay) {
                    return visit.repeatingDay === offerSeat.repeatingDay && offerSeat.visitId === visit.id;
                }

                return true;
            })
            .filter((offerSeat) => !offerSeat.completed && !offerSeat.deleted && !offerSeat.cancelled);
    }

    return undefined;
}

export enum JobVisitsTimelineFilter {
    None,
    Past,
    FromToday,
    ScheduleLater,
}

/**
 * Convert JobOfferSeatResponse to CreateJobOfferSeatInput.
 */
export function JobOfferSeatResponseToCreateInput(offerSeat: JobOfferSeatResponse): CreateJobOfferSeatInput {
    return {
        paymentType: offerSeat.paymentType,
        hours: offerSeat.hours || 0,
        minutes: offerSeat.minutes || 0,
        hourRate: offerSeat.hourRate || 0,
        fixedPrice: offerSeat.fixedPrice || 0,
        employeeIds: offerSeat.employeeIds,
        substituteCount: offerSeat.substituteCount || 0,
    };
}

export interface IVisitEventConflict {
    employeeId: number;
    date: DateTime;
    start: DateTime;
    end: DateTime;
    visitId: number;
    repeatingDay: number | undefined;
    jobId: number;
}

export interface IVisitEventConflictsParams {
    employeeId: number;
    visitsForConflicts: IVisitEvent[];
    visitOfferSeatsForConflicts: JobOfferSeatResponse[] | NullOrUndefined;
    visitsNewEvents: IVisitEvent[];
}

/**
 * Calculate VisitEvent conflicts for Employee at specific dates.
 */
export function visitEventConflicts(
    params: IVisitEventConflictsParams,
): IVisitEventConflict[] {
    const {employeeId, visitsForConflicts, visitsNewEvents, visitOfferSeatsForConflicts} = params;

    const conflicts: IVisitEventConflict[] = [];

    const visitsForConflictsForEmployee = visitsForConflicts.filter(visit => {
        const jobOfferSeats = filterJobOfferSeatsForVisit(visit, visitOfferSeatsForConflicts);

        if (jobOfferSeats && jobOfferSeats.some(offerSeat => offerSeat.employeeIds.includes(employeeId) || offerSeat.acceptedIds.includes(employeeId))) {
            return true;
        }

        return visit.employeeIds.includes(employeeId);
    });

    for (const visit of visitsNewEvents) {
        const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);
        const startOfVisit = dateTimes.start;
        const endOfVisit = dateTimes.end;

        if (startOfVisit && endOfVisit) {
            let datesOfVisitToValidate = [{
                start: startOfVisit,
                end: endOfVisit,
            }];

            if (dateTimes.individualDays && dateTimes.individualDays.length > 0) {
                datesOfVisitToValidate = dateTimes.individualDays.map((date, index) => {
                    return {
                        start: date.set({
                            hour: startOfVisit.hour,
                            minute: startOfVisit.minute,
                            second: startOfVisit.second
                        }),
                        end: date.set({hour: endOfVisit!.hour, minute: endOfVisit!.minute, second: endOfVisit!.second}),
                    };
                });
            }

            for (const dateOfVisitToValidate of datesOfVisitToValidate) {
                for (const visitForConflict of visitsForConflictsForEmployee) {
                    const dateTimesForConflict = visitDateTimes(visitForConflict, visitForConflict.visitRepeatDay, visitForConflict.repeatingDay, visitForConflict);
                    const startOfVisitForConflict = dateTimesForConflict.start;
                    const endOfVisitForConflict = dateTimesForConflict.end;

                    if (startOfVisitForConflict && endOfVisitForConflict) {
                        let datesOfVisitToValidateForConflict = [{
                            start: startOfVisitForConflict,
                            end: endOfVisitForConflict,
                        }];

                        if (dateTimesForConflict.individualDays && dateTimesForConflict.individualDays.length > 0) {
                            datesOfVisitToValidateForConflict = dateTimesForConflict.individualDays.map((date, index) => {
                                return {
                                    start: date.set({
                                        hour: startOfVisitForConflict.hour,
                                        minute: startOfVisitForConflict.minute,
                                        second: startOfVisitForConflict.second
                                    }),
                                    end: date.set({
                                        hour: endOfVisitForConflict!.hour,
                                        minute: endOfVisitForConflict!.minute,
                                        second: endOfVisitForConflict!.second
                                    }),
                                };
                            });
                        }

                        for (const dateOfVisitToValidateForConflict of datesOfVisitToValidateForConflict) {
                            if (isOverlapping(dateOfVisitToValidate.start, dateOfVisitToValidate.end, dateOfVisitToValidateForConflict.start, dateOfVisitToValidateForConflict.end)) {
                                conflicts.push({
                                    employeeId: employeeId,
                                    date: dateOfVisitToValidate.start,
                                    start: dateOfVisitToValidate.start,
                                    end: dateOfVisitToValidate.end,
                                    visitId: visitForConflict.id,
                                    repeatingDay: visitForConflict.repeatingDay,
                                    jobId: visitForConflict.jobId,
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    return conflicts;
}

/**
 * Convert unitName and unitCount to single line text.
 */
export function unitNameAndCountText(
    unitName: string | NullOrUndefined,
    unitCount: number | NullOrUndefined,
    language: string,
): string {
    const formatterMinutes = Intl.NumberFormat(language, {maximumFractionDigits: 2});

    return `${unitCount != null ? formatterMinutes.format(unitCount) : ''} ${unitName || ''}`.trim();
}

/**
 * UnitCount correct format.
 */
export function countText(
    unitCount: number | NullOrUndefined,
    language: string,
): string {
    const formatterMinutes = Intl.NumberFormat(language, {maximumFractionDigits: 2});

    return unitCount != null ? formatterMinutes.format(unitCount) : '';
}

/**
 * Filter JobEmployeeDataResponses for given employee and visit with respect to dynamic repeat.
 * Employee is optional, in that case check only for given visit.
 */
export function jobEmployeeDataForVisitAndEmployee(
    visitId: number,
    repeatingDay: number | NullOrUndefined,
    employeeId: number | NullOrUndefined,
    jobEmployeeData: JobEmployeeDataResponse[],
): JobEmployeeDataResponse[] {
    return jobEmployeeData.filter((data) => {
        if (employeeId && data.employeeId !== employeeId) {
            return false;
        }

        if (repeatingDay && !data.repeatingDay) {
            const thisForRepeatDay = jobEmployeeData.find((other) => {
                return other.repeatingDay === repeatingDay && data.employeeId === other.employeeId;
            });

            if (thisForRepeatDay) {
                return false;
            }
        }

        return data.visitId === visitId && (!data.repeatingDay || data.repeatingDay === repeatingDay);
    });
}

/**
 * Filter JobEmployeeTimesheetItemResponses for given employee and visit with respect to dynamic repeat.
 * Employee is optional, in that case check only for given visit.
 */
export function jobEmployeeTimesheetForVisitAndEmployee(
    visitId: number,
    repeatingDay: number | NullOrUndefined,
    employeeId: number | NullOrUndefined,
    jobEmployeeTimesheet: JobEmployeeTimesheetItemResponse[],
): JobEmployeeTimesheetItemResponse[] {
    return jobEmployeeTimesheet.filter((data) => {
        if (employeeId && data.employeeId !== employeeId) {
            return false;
        }

        if (repeatingDay && !data.repeatingDay) {
            const thisForRepeatDay = jobEmployeeTimesheet.find((other) => {
                return other.repeatingDay === repeatingDay && data.employeeId === other.employeeId && data.uuid === other.uuid;
            });

            if (thisForRepeatDay) {
                return false;
            }
        }

        return data.visitId === visitId && (!data.repeatingDay || data.repeatingDay === repeatingDay);
    });
}

export interface ICalculateVisitTotalsParams {
    employeePermissionsMap: Record<string, string[]> | NullOrUndefined;
    visitData?: VisitDetailResponse | NullOrUndefined;
    visitEvent?: IVisitEvent | NullOrUndefined;
    employeeTimesheets?: JobEmployeeTimesheetItemResponse[] | NullOrUndefined;
    employeeJobData?: JobEmployeeDataResponse[] | NullOrUndefined;
    jobOfferSeats?: JobOfferSeatResponse[] | NullOrUndefined;
    products?: ProductResponse[] | NullOrUndefined;
    materials?: MaterialResponse[] | NullOrUndefined;
    ignorePermissions?: boolean;
    countJobOfferSeats?: boolean;
}

/**
 * Calculate profit stats for Visit.
 */
export function calculateVisitTotals(params: ICalculateVisitTotalsParams) {
    const {
        employeePermissionsMap,
        visitData,
        visitEvent,
        employeeTimesheets,
        employeeJobData,
        jobOfferSeats,
        products,
        materials,
        ignorePermissions,
        countJobOfferSeats = true,
    } = params;

    let totalPrice: number = 0;
    let totalPriceWithVat: number = 0;
    let totalPriceProductMaterials: number = 0;
    let totalPriceProductMaterialsWithVat: number = 0;
    let totalCost: number = 0;
    let totalCostWithVat: number = 0;
    let totalCostEmployees: number = 0;
    let profitPercent: number = 0;
    let profit: number = 0;
    let isNegative: boolean = false;
    let totalHours: number = 0;
    let totalMinutes: number = 0;
    let totalDistance: number = 0;

    if (visitData || visitEvent) {
        const visitId = visitData?.visit.id || visitEvent!.id;
        const repeatingDay = visitData?.visit.repeatingDayData?.repeatingDay || visitEvent?.repeatingDay;

        const theFilterByEmployeeIds = visitData?.visit.repeatingDayData?.employeeIds || visitData?.visit.employeeIds || visitEvent!.visitRepeatDay?.employeeIds || visitEvent!.employeeIds;
        const theEmployeeTimesheets = ignorePermissions || hasPermission(kPermissionsTimesheets, [kActionView], employeePermissionsMap) ? (visitData ? visitData.employeeTimesheet : FilterJobEmployeeTimesheetItems({
            jobEmployeeTimesheetItems: employeeTimesheets,
            repeatingDay: repeatingDay,
            filterByVisitId: visitId,
        })) : [];
        const theEmployeeJobData = visitData ? visitData.employeeJobData : filterJobEmployeeData({
            jobEmployeeData: employeeJobData,
            repeatingDay: repeatingDay,
            filterByVisitId: visitId,
        });
        const theJobOfferSeats = (ignorePermissions || hasPermission(kPermissionsTimesheets, [kActionView], employeePermissionsMap) ? (visitData ? (visitData.jobOfferSeat || []) : (filterJobOfferSeatsForVisit(
            visitEvent!,
            jobOfferSeats,
        ) || [])) : []).filter((data) => {
            return !data.completed && !data.deleted && !data.cancelled && data.acceptedIds.length === 0;
        });
        const theProducts = ignorePermissions || hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap) ? (visitData ? visitData.products : filterProductsForVisit({
            products: products,
            repeatingDay: repeatingDay,
            visitId: visitId,
        })) : [];
        const theMaterials = ignorePermissions || hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap) ? (visitData ? visitData.materials : filterMaterialsForVisit({
            materials: materials,
            repeatingDay: repeatingDay,
            visitId: visitId,
        })) : [];

        const combinedItems = convertProductMaterials({
            products: theProducts,
            materials: theMaterials,
        });

        const statsForTimesheets = calculateTimesheets({
            timesheets: theEmployeeTimesheets,
            countNotApproved: false,
            filterByEmployeeIds: theFilterByEmployeeIds,
            filterByJobEmployeeData: theEmployeeJobData,
            repeatingDay: repeatingDay,
        });

        totalHours = statsForTimesheets.hours;
        totalMinutes = statsForTimesheets.minutes;
        totalDistance = statsForTimesheets.travel.distance;

        totalCost += statsForTimesheets.totalPrice;
        totalCostWithVat += statsForTimesheets.totalPrice;
        totalCostEmployees += statsForTimesheets.totalPrice;

        if (countJobOfferSeats) {
            theJobOfferSeats.forEach((value) => {
                totalHours += value.hours || 0;
                totalMinutes += value.minutes || 0;
            });

            const jobOfferSeatsTotal = CalculateTotalPrice({
                timesheets: theJobOfferSeats.map(seat => ({
                    paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                    hours: seat.hours,
                    minutes: seat.minutes,
                    hourRate: seat.hourRate,
                    fixedPrice: seat.fixedPrice,
                    approved: true,
                })),
                currency: '',
                language: '',
            });

            totalCost += jobOfferSeatsTotal;
            totalCostWithVat += jobOfferSeatsTotal;
            totalCostEmployees += jobOfferSeatsTotal;
        }

        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;

        for (const item of combinedItems) {
            const price = item.product?.price || item.material?.price || 0;
            const cost = item.product?.cost || item.material?.cost || 0;
            const vatRate = item.product?.vatRate || item.material?.vatRate || 0;
            const unitCount = item.product?.unitCount || item.material?.unitCount || 0;

            const priceWithCount = price * unitCount;
            const costWithCount = cost * unitCount;

            totalPrice += priceWithCount;
            totalCost += costWithCount;

            totalPriceWithVat += AddVatToPrice(priceWithCount, vatRate);
            totalCostWithVat += AddVatToPrice(costWithCount, vatRate);
        }

        profit = Math.abs(totalPrice - totalCost);
        isNegative = totalPrice < totalCost;

        const theProfit = totalPrice - totalCost;
        if (theProfit >= 0) {
            if (totalPrice > 0 && totalCost > 0) {
                profitPercent = (theProfit / totalPrice) * 100;
            } else if (totalPrice > 0) {
                profitPercent = 100;
            }
        } else {
            if (totalPrice > 0 && totalCost > 0) {
                profitPercent = (theProfit / totalPrice) * 100;
            } else if (totalPrice > 0) {
                profitPercent = 100;
            }
        }
    }

    return {
        price: totalPrice,
        priceWithVat: totalPriceWithVat,
        priceProductMaterials: totalPriceProductMaterials,
        priceProductMaterialsWithVat: totalPriceProductMaterialsWithVat,
        cost: totalCost,
        costWithVat: totalCostWithVat,
        costEmployees: totalCostEmployees,
        profitPercent,
        profit,
        profitRaw: totalPrice - totalCost,
        isNegative,
        totalHours,
        totalMinutes,
        totalDistance,
    };
}
