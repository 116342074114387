import {EmployeeRole, FileResponse, UserResponse} from "../generated/graphql/graphql";
import {tt} from "../core/Localization";
import {IAppDataContext} from "../AppData";
import INotification from "../model/firestore/Notification";
import {DateTime} from "luxon";

export const kStorageCompanyId = 'user_companyId';
export const kStorageUtmMarketingData = 'user_utm_marketing_data';

/**
 * User can be in multiple companies, we determine which to use.
 */
export function UserCompany(companyIds: number[]): number | undefined {
    if (companyIds.length === 0) {
        return undefined;
    }

    const storedCompanyId = localStorage.getItem(kStorageCompanyId);

    if (storedCompanyId && companyIds.includes(parseInt(storedCompanyId))) {
        return parseInt(storedCompanyId);
    }

    return companyIds[0];
}

/**
 * Switch whole app another Company.
 */
export function UserSetCompany(appDataContext: IAppDataContext, companyId: number | undefined) {
    const {setCompanyId} = appDataContext;

    if (companyId !== undefined) {
        localStorage.setItem(kStorageCompanyId, `${companyId}`);
    }

    setCompanyId(companyId);
}

/**
 * Start switching process to another Company.
 * This way loader is shown first, then we switch.
 */
export function UserStartSwitchCompany(
    appDataContext: IAppDataContext,
    companyId: number | undefined,
    message?: string | undefined,
) {
    const {setSwitchToCompanyId, setSwitchToCompanyMessage} = appDataContext;

    setSwitchToCompanyId(companyId);

    setSwitchToCompanyMessage(message);
}

/**
 * Combine name and surname into full name.
 */
export function UserFullName(name: string | NullOrUndefined, surname: string | NullOrUndefined): string {
    if (name && surname) {
        return `${name} ${surname}`;
    }

    if (name) {
        return name;
    }

    if (surname) {
        return surname;
    }

    return tt('common.notFilledIn');
}

/**
 * User role to translated string.
 */
export function UserRoleTitle(role: EmployeeRole | undefined): string {
    switch (role) {
        case EmployeeRole.Admin:
            return tt('employeeRole.admin');
        case EmployeeRole.Manager:
            return tt('employeeRole.manager');
        case EmployeeRole.HeadOfWorkers:
            return tt('employeeRole.headOfWorkers');
        case EmployeeRole.Worker:
            return tt('employeeRole.worker');
        default:
            return '';
    }
}

/**
 * Find a first available photo url and add type large in order to force
 * storing larger avatar image if available
 */
export function convertAvatarUrlToLarge(
    url?: string | NullOrUndefined,
): string | null {
    return url != null ? `${url}?type=large` : null
}

/**
 * Get correct photo url of User if available.
 * If not available, then return null.
 */
export function UserPhotoUrl(
    user: UserResponse | NullOrUndefined,
    files: FileResponse[] | NullOrUndefined,
    urls: Record<string, string> | NullOrUndefined,
    fallbackFromCustomPhoto?: boolean,
): string | NullOrUndefined {
    if (user) {
        if (files && user.customPhotoId && urls) {
            const file = files.find((f) => f.id === user.customPhotoId);

            if (file && (urls[file.uuid] || !fallbackFromCustomPhoto)) {
                return urls[file.uuid];
            }
        }

        return user.photoUrl;
    }

    return null;
}

/**
 * Get correct photo url of User if available.
 * Data is taken from INotification.
 * If not available, then return null.
 */
export function UserPhotoUrlOfNotification(
    notification: INotification | NullOrUndefined,
    urls: Record<string, string> | NullOrUndefined,
): string | NullOrUndefined {
    if (notification && notification.firebaseFileUuid && urls) {
        if (urls[notification.firebaseFileUuid]) {
            return urls[notification.firebaseFileUuid];
        }
    }

    return notification?.photoUrl;
}

/**
 * Check url for utm marketing data.
 * Save to local storage.
 */
export function checkUtmMarketingData() {
    const params = new URLSearchParams(window.location.search);

    const data = {
        referer: document.referrer,
    } as any;

    if (params.has('utm_source')) {
        data.utm_source = params.get('utm_source');
        data.utm_medium = params.get('utm_medium');
        data.utm_campaign = params.get('utm_campaign');
        data.utm_content = params.get('utm_content');
    }

    localStorage.setItem(kStorageUtmMarketingData, JSON.stringify(data));
}

/**
 * Get utm marketing data from local storage.
 * Clear from local storage.
 */
export function getUtmMarketingData(): any | null {
    const data = localStorage.getItem(kStorageUtmMarketingData);

    if (data) {
        localStorage.removeItem(kStorageUtmMarketingData);

        return JSON.parse(data);
    }

    return null;
}
