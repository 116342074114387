import './App.css';

import React, {createContext, Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import AppData from "./AppData";
import {CssBaseline, Theme} from "@mui/material";
import Router from "./core/Router";
import AutoSignInProcessor from "./core/AutoSignInProcessor";
import {makeStyles} from "tss-react/mui";
import FullSizedContainer from "./ui/components/FullSizedContainer";
import {initializeApp} from "firebase/app";
import {FlavorByEnvironment} from "./flavor-config";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import IAuthUser from "./model/AuthUser";
import AppThemeProcessor from "./styles/AppThemeProcessor";
import {Toaster} from "react-hot-toast";
import AppModals from "./ui/components/modals/AppModals";
import {BrowserRouter} from 'react-router-dom';
import {Loader} from "@mantine/core";
import RestApiProvider, {k30Minutes} from "./core/RestApiProvider";
import Spotlight from "./core/Spotlight";
import StorageProvider from "./core/StorageProvider";
import {LoginProvider} from "./generated/graphql/graphql";
import { Buffer } from 'buffer';
import {checkUtmMarketingData} from "./service/UserService";

const config = FlavorByEnvironment();

initializeApp(config!.firebase);

declare global {
    interface Window {
        dummyInitMaps: any;
    }
}
window.dummyInitMaps = () => {
};
const script = document.createElement('script');
script.id = 'maps.googleapis.com-script';
script.src = `https://maps.googleapis.com/maps/api/js?key=${config!.firebase.apiKey}&libraries=places&callback=dummyInitMaps`;
document.head.append(script);

const useStyles = makeStyles()((theme: Theme) => ({
    app: {
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
    },
}));

const title = document.getElementById('document-title');
const appTitle = title && title.dataset.title ? title.dataset.title : '';

export interface IAppContext {
    isFocused: boolean;
    title?: string;
    setTitle: Dispatch<SetStateAction<string | undefined>>;
    currentLoginProvider?: LoginProvider;
    setCurrentLoginProvider: Dispatch<SetStateAction<LoginProvider | undefined>>;
    authUser?: IAuthUser;
    setAuthUser: Dispatch<SetStateAction<IAuthUser | undefined>>;
    setAuthUserVerified: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

const kTenMinutesInMilliseconds = 1000 * 60 * 10;
const kDefaultPollInterval = kTenMinutesInMilliseconds;

/**
 * Main React App component.
 */
export default function App() {
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        document.title = title ? `${title} - ${appTitle}` : appTitle;
    }, [title]);

    const {classes} = useStyles();

    const [isFocused, setIsFocused] = useState<boolean>(true);
    const [currentLoginProvider, setCurrentLoginProvider] = useState<LoginProvider>();
    const [authUser, setAuthUser] = useState<IAuthUser>();
    const [authUserVerified, setAuthUserVerified] = useState<boolean>(false);

    useEffect(() => {
        const onBlur = () => {
            setIsFocused(false);
        };

        const onFocus = () => {
            setIsFocused(true);
        };

        window.addEventListener('blur', onBlur);
        window.addEventListener('focus', onFocus);

        return () => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('focus', onFocus);
        };
    }, []);

    useEffect(() => {
        checkUtmMarketingData();
    }, []);

    // console.log('TCH_d', authUser?.email, authUser?.idToken);
    // console.log('TCH_d', authUser?.uid);
    const authEmail = authUser?.email;
    const authIdToken = authUser?.idToken;

    const apolloClient = useMemo(() => {
       return new ApolloClient({
            link: createHttpLink({
                uri: config!!.graphqlEndpoint,
                headers: {
                    // Authorization: `Basic ${btoa(`${authEmail}:${authIdToken}`)}`,
                    Authorization: `Basic ${Buffer.from(`${authEmail}:${authIdToken}`).toString('base64')}`,
                },
            }),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                    pollInterval: kDefaultPollInterval,
                },
                query: {
                    pollInterval: kDefaultPollInterval,
                },
            },
        });
    }, [authEmail, authIdToken]);

    const appContext: IAppContext = {
        isFocused,
        title, setTitle,
        currentLoginProvider, setCurrentLoginProvider,
        authUser, setAuthUser,
        setAuthUserVerified,
    };

    const contentJSX = authUserVerified ? (
        <Router/>
    ) : (
        <FullSizedContainer>
            <Loader size="xl"/>
        </FullSizedContainer>
    );

    return (
        <>
            <RestApiProvider
                appContext={appContext}
                baseUri={config!!.restEndpoint}
                pollSubscriptionsInterval={k30Minutes}
            >
                <ApolloProvider client={apolloClient}>
                    <AppContext.Provider value={appContext}>
                        <AppData>
                            <StorageProvider>
                                <AutoSignInProcessor>
                                    <AppThemeProcessor>
                                        <BrowserRouter>
                                            <Spotlight>
                                                <>
                                                    <CssBaseline/>

                                                    <div className={classes.app}>
                                                        {contentJSX}
                                                    </div>

                                                    <AppModals/>
                                                </>
                                            </Spotlight>
                                        </BrowserRouter>
                                    </AppThemeProcessor>
                                </AutoSignInProcessor>
                            </StorageProvider>
                        </AppData>
                    </AppContext.Provider>
                </ApolloProvider>
            </RestApiProvider>

            <Toaster/>
        </>
    );
}
